<template>
  <div class="h-100">
    <div class="h-100" style="display: flex; flex-direction: column">
      <div
        class="d-flex align-items-center justify-content-between"
        style="padding: 0 2% 0 1.5%"
      >
        <p class="m-0 TitleTop">Scored Quiz</p>
        <div class="d-flex align-items-center">
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="2" y="2" width="40" height="40" rx="20" fill="#EADDF6" />
            <rect
              x="2"
              y="2"
              width="40"
              height="40"
              rx="20"
              stroke="#F4EFF9"
              stroke-width="4"
            />
            <path
              d="M21.25 17.8417C21.25 18.2559 21.5858 18.5917 22 18.5917C22.4142 18.5917 22.75 18.2559 22.75 17.8417H21.25ZM22.75 17.8333C22.75 17.4191 22.4142 17.0833 22 17.0833C21.5858 17.0833 21.25 17.4191 21.25 17.8333H22.75ZM21.25 26.1667C21.25 26.5809 21.5858 26.9167 22 26.9167C22.4142 26.9167 22.75 26.5809 22.75 26.1667H21.25ZM22.75 20.3333C22.75 19.9191 22.4142 19.5833 22 19.5833C21.5858 19.5833 21.25 19.9191 21.25 20.3333L22.75 20.3333ZM22.75 17.8417V17.8333H21.25V17.8417H22.75ZM22.75 26.1667L22.75 20.3333L21.25 20.3333L21.25 26.1667H22.75ZM28.75 22C28.75 25.7279 25.7279 28.75 22 28.75V30.25C26.5563 30.25 30.25 26.5563 30.25 22H28.75ZM22 28.75C18.2721 28.75 15.25 25.7279 15.25 22H13.75C13.75 26.5563 17.4437 30.25 22 30.25V28.75ZM15.25 22C15.25 18.2721 18.2721 15.25 22 15.25V13.75C17.4437 13.75 13.75 17.4437 13.75 22H15.25ZM22 15.25C25.7279 15.25 28.75 18.2721 28.75 22H30.25C30.25 17.4437 26.5563 13.75 22 13.75V15.25Z"
              fill="#4D1B7E"
            />
          </svg>

          <div class="d-flex flex-column ml-2">
            <p
              class="m-0"
              style="
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                color: #000;
              "
            >
              How to use product score?
            </p>
            <a
              href="https://support.quizell.com/blogs/news/what-is-quiz-score-and-how-it-works"
              target="_blank"
              class="helpLinkStyles mt-1"
              >Visit out help center</a
            >
          </div>
        </div>
      </div>

      <div
        class="flex-grow-1"
        style="
          padding: 15px 2% 15px 1.5%;
          max-height: calc(100vh - 200px);
          overflow-y: scroll;
          overflow-x: hidden;
        "
      >
        <div
          class="d-flex align-items-center justify-content-between my-2"
          style="border: 1px solid #eaecf0; padding: 20px 10px;border-radius: 16px;"
        >
          <div>
            <p
              style="
                color: #1f1f28;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
              "
            >
              Enable to set up score for each question
            </p>
            <p class="m-0"
              style="
                color: #667085;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              "
            >
              Сhoose how many points will be awarded to the user for selecting a
              certain answer.
            </p>
          </div>

          <span class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="show-score-status"
              v-model="count_score_status"
            />
            <label class="custom-control-label" for="show-score-status"></label>
          </span>
        </div>

        <div class="">
          <div class="pb-1" style=" border-radius: 16px;
            border: 1px solid #ededed;
            background: #fff;">
            <div class="row">
              <div class="col-4 pr-0">
                <div class="px-4 " style=" border-bottom: 1px solid #ededed;
                  background: #f9f8f9;
                  border-right: 1px solid #ededed;
                  padding: 12px 12px;
                  height: 50px;">
                  <h5 class="sectionTitle " style="color: #18191c">
                    Questions:
                  </h5>
                </div>
                  <div class="w-100 px-4 " v-if="isProductLoading">
                    <div class="py-3" v-for="index in 5" :key="index">
                      <b-skeleton class="w-100" type="input"></b-skeleton>
                    </div>
                  </div>
                  <div v-else class="w-100 QuestionsDiv px-1 ">
                    <div v-if="questions.length" class="p-0">
                      <div
                        v-for="(question, index) in questions"
                        :key="index"
                        class="w-100  singleQuestion py-2 mt-2 mb-3 d-flex align-items-center"
                        @click="selectedQuestion = question.id"
                        :class=" isQuestionSelected(question) ? 'singleQuestionSelected' : null"
                       
                      >

                      <div
                    class="d-flex align-items-center"
                    :style="
                       isQuestionSelected(question) ? 'flex:0.9;' : 'flex:1;'
                    "
                  >
                    <div
                      class="Order d-flex align-items-center justify-content-center mr-3"
                    >
                      <span> {{ index + 1 }}</span>
                    </div>
                    <div>
                      <span
                        style="
                          color: #1f1f28;
                          font-family: Poppins;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                        "
                        >{{ question.name }}</span
                      >
                    </div>
                  </div>
                  <div v-if=" isQuestionSelected(question)" style="flex: 0.1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M14.1667 10.0001L5.83334 10.0001M14.1667 10.0001L10.8333 13.3334M14.1667 10.0001L10.8333 6.66675"
                        stroke="#4D1B7E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                       
                      </div>
                    </div>
                    <div class="p-0" v-else>
                      <h5 style="color: #18191c; text-align: center">
                        No Question Added.
                      </h5>
                    </div>
                  </div>
                
              </div>
              <div class="col-8 midSection pl-0" style="">
                <div style=" border-bottom: 1px solid #ededed;
                  background: #f9f8f9;
                  border-right: 1px solid #ededed;
                  padding: 12px 12px;
                  height: 50px;">
                  <h5 class="sectionTitle" style="color: #18191c">
                    Options Score:
                  </h5>
                </div>
                  <div class="w-100 px-5" v-if="isProductLoading">
                    <div class="py-3" v-for="index in 5" :key="index">
                      <b-skeleton class="w-100" type="input"></b-skeleton>
                    </div>
                  </div>
                  <div class="w-100 px-1" v-else>
                    <div class="Options p-0" v-if="getSelectedQuestionOption">
                      <div
                        v-for="(option, index) in getSelectedQuestionOption"
                        :key="index"
                        class="w-100 singleOption my-2"
                        @click="SelectedOption(option, index)"
                        
                      >
                        <div class="d-flex align-items-center ">
                          <div class="" style="flex: 1">
                            <p class="mb-n1 text-left OptionText">
                              {{ option.option_value }}
                            </p>
                          </div>

                          <div>
                            <div class="d-flex align-items-center ScoreDiv">
                              <button
                                class="btn ScoreBtn"
                                @click="decrementOptionScore(index)"
                              >
                                -
                              </button>
                             
                              <input
                                type="number"
                                class="ScoreNum"
                                v-model="option.score_value"
                                style="
                                  border: none;
                                  outline: none;
                                  background: transparent;
                                "
                              />

                              <button
                                class="btn ScoreBtn"
                                @click="incrementOptionScore(index)"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else>
                      <h5 style="color: #18191c; text-align: center">
                        Select a question.
                      </h5>
                    </div>
                  </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="px-2 pt-2 d-flex justify-content-end"
        style="
          border-top: 1px solid #eaecf0;
          background: #fff;
          box-shadow: 0px -4px 11px 0px rgba(31, 31, 40, 0.07);
        "
      >
      <button
          class="btn text-white mx-2 mt-2"
          style="
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            border-radius: 6px;
            background: #4d1b7e;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          "
          @click="saveData"
          :disabled="ProductSpinner"
        >
          <span v-if="!ProductSpinner"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M14.697 8.03033C14.9899 7.73744 14.9899 7.26256 14.697 6.96967C14.4041 6.67678 13.9292 6.67678 13.6363 6.96967L14.697 8.03033ZM8.33332 13.3333L7.80299 13.8637C8.09588 14.1566 8.57075 14.1566 8.86365 13.8637L8.33332 13.3333ZM6.36361 10.303C6.07072 10.0101 5.59584 10.0101 5.30295 10.303C5.01006 10.5959 5.01006 11.0708 5.30295 11.3637L6.36361 10.303ZM13.6363 6.96967L7.80299 12.803L8.86365 13.8637L14.697 8.03033L13.6363 6.96967ZM5.30295 11.3637L7.80299 13.8637L8.86365 12.803L6.36361 10.303L5.30295 11.3637ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25Z" fill="white"/>
</svg>  Save Changes</span>
          <div v-else class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import LoaderComp from "../component/LoaderComp.vue";
import axios from "axios";
// import ResultScore from "./ResultScore.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    // LoaderComp,
    // ResultScore
  },
  data() {
    return {
      unselectALL: false,
      ProductSpinner: false,
      isProductLoading: false,
      questions: [],
      selectedQuestion: null,
      count_score_status: null,
    };
  },
  computed: {
    ...mapGetters(["getEditorSettings", "GetTokenFromMetaTag", "getQuizID"]),

    getSelectedQuestionOption() {
      if (this.selectedQuestion) {
        return this.questions[this.selectedQuestionIndex].options;
      } else {
        return null;
      }
    },
    selectedQuestionIndex() {
      return this.questions.findIndex((x) => x.id == this.selectedQuestion);
    },
    getSelectedOptionIndex() {
      const SelectedOptionID =
        this.questions[this.selectedQuestionIndex].selectedOption;
      if (SelectedOptionID) {
        return this.questions[this.selectedQuestionIndex].options.findIndex(
          (x) => x.id == SelectedOptionID
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    openProductPage() {
      this.$router.push("/manage/products");
    },

    incrementOptionScore(index) {
      // this.questions[this.selectedQuestionIndex].options[index].option_detail = parseInt(this.questions[this.selectedQuestionIndex].options[index].option_detail) + 1;
      this.questions[this.selectedQuestionIndex].options[index].score_value =
        this.questions[this.selectedQuestionIndex].options[index].score_value +
        1;
    },
    decrementOptionScore(index) {
      // if( parseInt( this.questions[this.selectedQuestionIndex].options[index].option_detail) >= 1){
      //      this.questions[this.selectedQuestionIndex].options[index].option_detail = parseInt( this.questions[this.selectedQuestionIndex].options[index].option_detail) - 1;
      // }

      this.questions[this.selectedQuestionIndex].options[index].score_value =
        this.questions[this.selectedQuestionIndex].options[index].score_value -
        1;
    },
    async getProductScoreData() {
      this.isProductLoading = true;
      try {
        let data = {
          _token: this.GetTokenFromMetaTag,
          quizId: this.getQuizID
            ? this.getQuizID
            : localStorage.getItem("QuizID"),
        };
        const response = await axios.post(`/loadScoreMap`, data);
        if (response.status == 200 && response.data.status == "success") {
          if (response.data.data.count_score_status == 1) {
            this.count_score_status = true;
          } else {
            this.count_score_status = false;
          }

          const updateQuestions = response.data.data.questions;

          updateQuestions.forEach((data) => {
            return data.options.forEach((option) => {
              if (option.option_detail == "" || option.option_detail == null) {
                option.option_detail = "0";
              }
            });
          });
          // this.questions = response.data.data.questions;
          this.questions = updateQuestions;

          if (this.questions.length && this.questions[0].options.length) {
            this.selectedQuestion = this.questions[0].id;
            let option = this.questions[0].options[0];
            this.SelectedOption(option);
          }
        }
      } catch (error) {
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isProductLoading = false;
      }
    },
    async saveData() {
      const Questions = this.questions;
      let scoreStatus;

      if (this.count_score_status) {
        scoreStatus = 1;
      } else {
        scoreStatus = 0;
      }
      this.ProductSpinner = true;
      try {
        let data = {
          mapQuestions: JSON.stringify(Questions),
          _token: this.Token,
          quizId: parseInt(localStorage.getItem("QuizID")),
          count_score_status: scoreStatus,
        };
        const response = await axios.post(`/saveScoreMap`, data);
        if (response.status == 200) {
          this.$toasted.show("Product Score updated", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
            action: {
              class: "customToast",
            },
          });
          // await  this.getProductScoreData();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.ProductSpinner = false;
      }
    },

    // Styles Fucntion
    isQuestionSelected(ques) {
      if (this.selectedQuestion) {
        if (ques.id == this.selectedQuestion) return true;
      }
      return false;
    },
    selectedQuestionStyles() {
      return `background:#b3afb61a;`;
    },
    selectedQuestionOrderStyle() {
      return `background: #4D1B7E;
opacity: 0.8;
border-radius: 2px;`;
    },
    SelectedOption(option) {
      const QuesIndex = this.selectedQuestionIndex;
      this.questions[QuesIndex].selectedOption = option.id;
    },
    isOptionSelected(id) {
      if (id == this.questions[this.selectedQuestionIndex].selectedOption)
        return true;
      return false;
    },
    selectedOptionStyles() {
      return `color:#18191C;font-weight:500;background: #b3afb61a;box-shadow: 0px 6px 12px -6px rgba(0, 0, 0, 0.15);`;
    },
    selectedOptionOrderStyle() {
      return ` background: linear-gradient(180deg, #FFB800 0%, #FFA200 56.77%, #FFA200 90.1%);
opacity: 0.8;
border-radius: 2px;`;
    },
  },
  created() {
    this.getProductScoreData();
  },
};
</script>

<style scoped>
.TitleTop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #1f1f28;
}
.QuestionsDiv {
  height: 100%;
  max-height: 65vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.sectionTitle {
  color: #1f1f28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  letter-spacing: 0.56px;
}
.singleQuestion{
  border-radius: 8px;
  padding: 6px 6px 6px 16px;
  cursor: pointer;
}
.singleQuestionSelected {

  border-radius: 8px;
  background: #b3afb626;
}
.singleQuestion .Order {
  border: 1px solid #4d1b7e;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 12px;
  padding: 10px;
  font-weight: 500;
  color: #4d1b7e;
}


.Options {
  height: 100%;
  max-height: 65vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.Options .singleOption {
  border-radius: 8px;
  padding: 6px 6px 6px 16px;
}
.Options .singleOption:hover {
  background: #b3afb626;
  /* background: #F9F8F9; */
  cursor: pointer;
}
.singleOption .OptionText {
  color: #1F1F28;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.singleOption .Order {
  background: #b3afb6;
  width: 25px !important;
  height: 20px;
  color: #ffffff;
  border-radius: 2px;
}

.midSection .ScoreDiv {
  border-radius: 2px;
}

.midSection .ScoreDiv .ScoreBtn {
  border-radius: 8px;
border: 1px solid #EDEDED;
background: #FFF;

box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.midSection .ScoreDiv .ScoreNum {
  color: #18191c;
  font-weight: 600;
  min-width: 40px;
  width: 50px;
  max-width: 60px;
  text-align: center;
}

.checkboxContainer input[type="checkbox"] {
  accent-color: #4d1b7e;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
</style>
